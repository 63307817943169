import { defineStore } from 'pinia'
import { storeToRefs } from 'pinia'
import { useMqttStore } from '@/store/mqttStore'
import { useBikeStore } from '@/store/bikeStore'
import { MqttValue, MqttMessage } from '../mqttDefinitions'
import simulateService from '@/service/simulateService'
import type { position } from '@/types/position'
import {
  handlebarCurrentPositionX,
  handlebarCurrentPositionY,
  saddleCurrentPositionX,
  saddleCurrentPositionY,
} from '@/mqttDefinitions'
import {
  saddleCurrentSetPointX,
  saddleCurrentSetPointY,
  handlebarCurrentSetPointX,
  handlebarCurrentSetPointY,
} from '@/mqttDefinitions'

const numberFormatter = new Intl.NumberFormat('en-US', {
  signDisplay: 'auto',
})

export const usePositionStore = defineStore({
  id: 'positionStore',
  state: (): {
    startPosition: position
    positionOffset: position
    positionOffsetGear: position
    positionDeviation: position
    isCalibrated: boolean
    isCalibrating: boolean
  } => ({
    // Start position as recieved from BFC/entered by bike dealer
    startPosition: {
      handlebarX: 0,
      handlebarY: 0,
      saddleX: 0,
      saddleY: 0,
    },

    // Difference between motorPosition and absolutePosition
    positionOffset: {
      handlebarX: 0,
      handlebarY: 0,
      saddleX: 0,
      saddleY: 0,
    },

    // Difference between motorPosition and absolutePosition after second calibration
    positionOffsetGear: {
      handlebarX: 0,
      handlebarY: 0,
      saddleX: 0,
      saddleY: 0,
    },

    // Difference between measuredPosition and absolutePosition
    positionDeviation: {
      handlebarX: 0,
      handlebarY: 0,
      saddleX: 0,
      saddleY: 0,
    },

    isCalibrated: false,
    isCalibrating: false,
  }),

  getters: {
    // Current motor position as recieved from the controller (in mm!)
    motorPosition(): position {
      const mqttStore = useMqttStore()
      return {
        handlebarX: mqttStore.getMessageOnTopic(handlebarCurrentPositionX),
        handlebarY: mqttStore.getMessageOnTopic(handlebarCurrentPositionY),
        saddleX: mqttStore.getMessageOnTopic(saddleCurrentPositionX),
        saddleY: mqttStore.getMessageOnTopic(saddleCurrentPositionY),
      } as position
    },

    // Current position as measured by the XY-tool
    measuredPosition(): position {
      return {
        handlebarX:
          this.absolutePosition.handlebarX + this.positionDeviation.handlebarX,
        handlebarY:
          this.absolutePosition.handlebarY + this.positionDeviation.handlebarY,
        saddleX: this.absolutePosition.saddleX + this.positionDeviation.saddleX,
        saddleY: this.absolutePosition.saddleY + this.positionDeviation.saddleY,
      } as position
    },

    // Current position as determined by the motor position and the corresponding offset
    // Since the handlebar X moves towards the 0 point, it's value is subtracted from the offset
    absolutePosition(): position {
      return {
        handlebarX:
          this.positionOffset.handlebarX +
          this.positionOffsetGear.handlebarX -
          this.motorPosition.handlebarX,
        handlebarY:
          this.positionOffset.handlebarY +
          this.positionOffsetGear.handlebarY +
          this.motorPosition.handlebarY,
        saddleX:
          this.positionOffset.saddleX +
          this.positionOffsetGear.saddleX +
          this.motorPosition.saddleX,
        saddleY:
          this.positionOffset.saddleY +
          this.positionOffsetGear.saddleY +
          this.motorPosition.saddleY,
      } as position
    },

    // Difference between startPosition and absolutePosition
    positionChange(): any {
      return {
        handlebarX: numberFormatter.format(
          this.absolutePosition.handlebarX - this.startPosition.handlebarX,
        ),
        handlebarY: numberFormatter.format(
          this.absolutePosition.handlebarY - this.startPosition.handlebarY,
        ),
        saddleX: numberFormatter.format(
          this.absolutePosition.saddleX - this.startPosition.saddleX,
        ),
        saddleY: numberFormatter.format(
          this.absolutePosition.saddleY - this.startPosition.saddleY,
        ),
      } as any
    },
  },

  actions: {
    submitPosition(definition: MqttValue, newValue: number) {
      const bikeStore = useBikeStore()
      const { hasPositioningMotors } = storeToRefs(bikeStore)

      if (hasPositioningMotors.value) {
        const mqttStore = useMqttStore()
        mqttStore.doPublish(
          {
            topic: definition.topic,
            payload: newValue,
          } as MqttMessage,
          definition,
          mqttStore.client,
        )
      } else {
        simulateService.publish(definition, newValue)
      }
    },

    calibratePosition() {
      // Add the difference to the offset to match
      if (!this.isCalibrated) {
        this.positionOffset.handlebarX +=
          this.positionDeviation.handlebarX - this.positionOffset.handlebarX
        this.positionOffset.handlebarY +=
          this.positionDeviation.handlebarY - this.positionOffset.handlebarY
        this.positionOffset.saddleX +=
          this.positionDeviation.saddleX - this.positionOffset.saddleX
        this.positionOffset.saddleY +=
          this.positionDeviation.saddleY - this.positionOffset.saddleY

        // Move the handlebar and saddle back to their original position if possible (motorvalue > 0)
        // Since the handlebar X moves towards the 0 point, it's value is added to the deviation
        this.submitPosition(
          handlebarCurrentSetPointX,
          this.motorPosition.handlebarX - this.positionDeviation.handlebarX,
        )
        this.submitPosition(
          handlebarCurrentSetPointY,
          this.motorPosition.handlebarY - this.positionDeviation.handlebarY,
        )
        this.submitPosition(
          saddleCurrentSetPointX,
          this.motorPosition.saddleX - this.positionDeviation.saddleX,
        )
        this.submitPosition(
          saddleCurrentSetPointY,
          this.motorPosition.saddleY - this.positionDeviation.saddleY,
        )
      } else {
        const changeInHandlebarX =
          this.positionDeviation.handlebarX - this.absolutePosition.handlebarX
        const changeInHandlebarY =
          this.positionDeviation.handlebarY - this.absolutePosition.handlebarY
        const changeInSaddleX =
          this.positionDeviation.saddleX - this.absolutePosition.saddleX
        const changeInSaddleY =
          this.positionDeviation.saddleY - this.absolutePosition.saddleY

        this.submitPosition(
          handlebarCurrentSetPointX,
          this.motorPosition.handlebarX + changeInHandlebarX,
        )
        this.submitPosition(
          handlebarCurrentSetPointY,
          this.motorPosition.handlebarY - changeInHandlebarY,
        )
        this.submitPosition(
          saddleCurrentSetPointX,
          this.motorPosition.saddleX - changeInSaddleX,
        )
        this.submitPosition(
          saddleCurrentSetPointY,
          this.motorPosition.saddleY - changeInSaddleY,
        )

        this.positionOffsetGear.handlebarX += changeInHandlebarX
        this.positionOffsetGear.handlebarY += changeInHandlebarY
        this.positionOffsetGear.saddleX += changeInSaddleX
        this.positionOffsetGear.saddleY += changeInSaddleY
      }
    },

    moveToStartPosition() {
      this.submitPosition(
        handlebarCurrentSetPointX,
        (this.startPosition.handlebarX - this.positionOffset.handlebarX) * -1,
      )
      this.submitPosition(
        handlebarCurrentSetPointY,
        this.startPosition.handlebarY - this.positionOffset.handlebarY,
      )
      this.submitPosition(
        saddleCurrentSetPointX,
        this.startPosition.saddleX - this.positionOffset.saddleX,
      )
      this.submitPosition(
        saddleCurrentSetPointY,
        this.startPosition.saddleY - this.positionOffset.saddleY,
      )
      this.isCalibrated = true
    },

    resetPosition() {
      this.submitPosition(handlebarCurrentSetPointX, 0)
      this.submitPosition(handlebarCurrentSetPointY, 0)
      this.submitPosition(saddleCurrentSetPointX, 0)
      this.submitPosition(saddleCurrentSetPointY, 0)

      this.positionOffset.handlebarX = 0
      this.positionOffset.handlebarY = 0
      this.positionOffset.saddleX = 0
      this.positionOffset.saddleY = 0

      this.isCalibrated = false
    },

    startHoming() {
      const mqttStore = useMqttStore()
      const { doRawPublish, client } = mqttStore

      doRawPublish(
        'bikefitting/motorcontroller/set/homing',
        JSON.stringify({}),
        client,
      )
    },

    doRestartMotors() {
      const mqttStore = useMqttStore()
      const { doRawPublish, client } = mqttStore

      doRawPublish(
        'bikefitting/motorcontroller/start',
        JSON.stringify({}),
        client,
      )
    },

    doReboot() {
      const mqttStore = useMqttStore()
      const { doRawPublish, client } = mqttStore

      doRawPublish(
        'bikefitting/Controller/reboot/set',
        JSON.stringify({}),
        client,
      )
    },

    doShutdown() {
      const mqttStore = useMqttStore()
      const { doRawPublish, client } = mqttStore

      doRawPublish(
        'bikefitting/Controller/shutdown/set',
        JSON.stringify({}),
        client,
      )
    },
  },
  persist: {
    storage: sessionStorage,
  },
})
